import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { MdAddShoppingCart, MdHistory } from 'react-icons/md';
import { TiDocumentText } from 'react-icons/ti';
import { useAuth } from '../../context/auth';
import api from '../../services/api';
import Modal from 'react-modal';
import confirmadoGif from '../../assets/images/confirmado1.gif';

import {
  Container,
  Content,
  HeaderClient,
  HeaderAction,
  Footer,
  ButtonOption,
  ButtonAction,
  HeaderPurchase,
  ClientDiv,
  SelectField,
  SelectStyled,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  CancelButton,
  ModalBody,
  ModalItem,
  ButtonGoToCart,
} from './styles';

import { Spinner, Row } from 'reactstrap';
import toast from 'react-hot-toast';

const Operation = props => {
  const { signOut } = useAuth();
  const [path, setPath] = useState('');
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const [phone, setCelphone] = useState('');
  const [total, setTotal] = useState('0,00');
  const [clientData, setClientData] = useState([]);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [rescued, setRescued] = useState(0);
  const [products, setProducts] = useState([]);
  const [mensagemCelular, setMensagemCelular] = useState(false);
  const [pointsSelected, setPointsSelected] = useState(0);
  const [pointsModel, setPointsModel] = useState(false);

  async function getStore() {
    try {
      const response = await api.get(`/store`);
      setUser(response.data);
      setSettings(response.data.settings[0]);
      const pointsModel =
        response.data.settings[0].model === 'points' ? true : false;
      setPointsModel(pointsModel);
    } catch (err) {
      if ((err = 'Error: Request failed with status code 401')) {
        signOut();
      }
      alert(err);
    }
  }

  function logOut() {
    signOut();
  }

  const gif = useRef(null);

  function handleChangeCelphone(e) {
    setCelphone(e.target.value);
  }

  function handleChangeTotal(e) {
    var valor = e.target.value;
    setTotal(valor);
    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ',$1');

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    }

    setTotal(valor);

    if (valor[0] == ',') {
      setTotal('0' + valor);
    }
    if (valor.length === 1) {
      setTotal('0,0' + valor);
    }
    if (valor == 'NaN') {
      setTotal('');
    }
  }

  async function getClient(phone) {
    setLoading(true);
    try {
      const response = await api.get(`/store/clients/${phone}`);
      setClient(response.data.client);
      setClientData(response.data);
    } catch (err) {
      if ((err = 'Error: Request failed with status code 401')) {
        signOut();
      }
      alert(err);
    }
    setLoading(false);
  }

  async function handlePurchase(phone, total) {
    try {
      if (phone.length < 15) {
        setMensagemCelular(true);
      } else if (
        isNaN(parseFloat(total.replace(',', '.'))) ||
        parseFloat(total.replace(',', '.')) <= 0
      ) {
        toast.error('Insira um valor de consumo para poder concluir');
      } else {
        total = total.replace('.', '');
        total = total.replace(',', '.');
        total = parseFloat(total).toFixed(2);
        total = total.toString();
        const parsedPhone = phone.replace(' ', '').replace('-', '');
        const products =
          selected && selected.length > 0
            ? selected.map(sel => sel.value)
            : null;

        let data = {
          phone: parsedPhone,
          price: total,
          products,
        };

        if (user.plan === 'premium') {
          if (selectedLembrete) {
            data.reminder = selectedLembrete;
          } else {
            data.reminder = defaultReminder.value;
          }
        }

        if (settings.model === 'cashback') {
          if (parseFloat(rescued) > 0) {
            data.rescueCashback = true;
          }
        } else {
          if (selectedPromotion) {
            data.rescuePoints = true;
            let promotionsTo = selectedPromotion.map(select => select.id);
            data.promotions = promotionsTo;
          }
        }

        const response = await api.post(`/store/purchases`, data);

        openModalConfirm();
        setCelphone('');
        setMensagemCelular(false);
        setTotal('0,00');
        setSelected([]);
        setSelectedLembrete(null);
        setRescued('0.00');
      }
    } catch (err) {
      alert(err);
    }
  }

  async function getProducts() {
    try {
      const response = await api.get(`/store/products`);

      if (response.data.length > 0) {
        const data = response.data.map(product => ({
          value: product.id,
          label: product.name,
          price: product.price,
        }));
        setProducts(data);
      }
    } catch (err) {
      if ((err = 'Error: Request failed with status code 401')) {
        signOut();
      }
      alert(err);
    }
  }

  const [promotions, setPromotions] = useState([]);

  async function getPromotions() {
    try {
      const response = await api.get(`/store/promotions`);
      setPromotions(response.data);
    } catch (err) {
      if ((err = 'Error: Request failed with status code 401')) {
        signOut();
      }
      alert(err);
    }
  }

  const dayDefault =
    user && user.settings[0] ? user.settings[0].reminder : null;
  let arrayDefault = [];
  if (dayDefault) {
    arrayDefault.push({
      value: dayDefault,
      label: dayDefault > 1 ? `${dayDefault} dias` : `${dayDefault} dia`,
    });
  } else {
    arrayDefault.push({
      value: null,
      label: 'Nenhum',
    });
  }

  const [reminders, setReminders] = useState([]);
  const [defaultReminder, setDefaultReminder] = useState(arrayDefault[0]);

  async function getReminder() {
    try {
      const response = await api.get(`/store/reminders`);
      setReminders(response.data.all);
      setDefaultReminder(response.data.default[0]);
    } catch (err) {
      if ((err = 'Error: Request failed with status code 401')) {
        signOut();
      }
      alert(err);
    }
  }

  const [cash, setCash] = useState(0);

  async function selectPromotion(promotion) {
    let selected = selectedPromotion;
    if (selected === null) {
      selected = [];
    }
    selected.push(promotion);
    setSelectedPromotion(selected);
    let newPointsSelected = pointsSelected + parseFloat(promotion.points);
    setPointsSelected(newPointsSelected);
  }

  useEffect(() => {
    if (phone.length === 15) {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      getClient(parsedPhone);
      setShowProfile(true);
      setMensagemCelular(false);
    } else {
      setShowProfile(false);
      setSelectedPromotion([]);
    }
  }, [phone]);

  useEffect(() => {
    if (settings) {
      if (settings.model === 'cashback') {
        const div = parseFloat(settings.percent);
        let new_total = total;
        new_total = new_total.replace('.', '');
        new_total = new_total.replace(',', '.');
        let res = (parseFloat(new_total) * div) / 100;
        res = res.toFixed(2);
        setCash(res);
      } else {
        let points_default = parseFloat(settings.points);
        let new_total = total;
        new_total = new_total.replace('.', '');
        new_total = new_total.replace(',', '.');
        let points = parseFloat(new_total) / points_default;
        points = parseInt(points);
        setCash(points);
      }
    }
  }, [total, settings]);

  useEffect(() => {
    getStore();
    getProducts();
    getReminder();
    if (pointsModel) {
      getPromotions();
    }
  }, [pointsModel]);

  const [selected, setSelected] = useState([]);
  const [selectedLembrete, setSelectedLembrete] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenConfirm, setIsOpenConfirm] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState([]);

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const customStylesConfirm = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: 'none',
      transform: 'translate(-50%, -50%)',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModalConfirm() {
    setIsOpenConfirm(true);
  }

  function closeModalConfirm() {
    setIsOpenConfirm(false);
  }

  useEffect(() => {
    if (modalIsOpenConfirm) {
      setTimeout(function () {
        closeModalConfirm();
      }, 2400);
    }
  }, [modalIsOpenConfirm]);

  return (
    <Container>
      {user && settings && (
        <Content>
          <HeaderAction>
            <header>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: 15,
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <h1>{user.name}</h1>
                  <p style={{ color: 'blue' }}>
                    https://app.takeat.club/{user.key}
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => {
                      logOut();
                    }}
                  >
                    {' '}
                    Sair
                  </button>
                </div>
              </div>
            </header>
          </HeaderAction>

          <HeaderClient>
            <div style={{ margin: 'auto', color: '#474747', fontSize: '25px' }}>
              Celular:
            </div>
            <InputMask
              mask="(99) 99999-9999"
              maskChar=""
              value={phone}
              onChange={e => handleChangeCelphone(e)}
            />
          </HeaderClient>
          {mensagemCelular && (
            <div style={{ textAlign: 'center' }}>
              <span style={{ color: 'red' }}>Preencha um número válido!</span>
            </div>
          )}

          {loading && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <span>
                <Spinner size="lg" color="red" />
              </span>
            </div>
          )}

          {showProfile &&
            JSON.stringify(clientData) !== '[]' &&
            clientData.clientExist && (
              <ClientDiv>
                {client && (
                  <>
                    <div
                      style={{
                        textAlign: 'left',
                        fontSize: '20px',
                        paddingLeft: 15,
                        color: '#7A7A7A',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '60%', color: '#26BAB5' }}>
                          {client.name}
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'right',
                            marginRight: 28,
                          }}
                        >
                          {settings.model === 'cashback' ? (
                            <div style={{ color: '#2DB42D' }}>
                              R${clientData.totalClientCashback}
                            </div>
                          ) : (
                            <div style={{ color: '#2DB42D' }}>
                              {clientData.totalClientPoints} ponto(s)
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '60%', fontSize: 17 }}>
                          {clientData.numCompras + 1}ª compra
                        </div>
                        <div style={{ width: '40%', textAlign: 'right' }}>
                          {console.log('settings > ', !settings.minimo)}
                          {settings.model === 'cashback' ? (
                            <div>
                              {(!settings.minimo ||
                                parseFloat(clientData.totalClientCashback) >=
                                  parseFloat(settings.minimo)) && (
                                <Row>
                                  <button
                                    onClick={() => {
                                      setRescued(
                                        clientData.totalClientCashback,
                                      );
                                    }}
                                  >
                                    Resgatar
                                  </button>
                                </Row>
                              )}
                            </div>
                          ) : (
                            <div>
                              {(!settings.minimo ||
                                parseFloat(clientData.totalClientPoints) >=
                                  parseFloat(settings.minimo)) && (
                                <Row>
                                  <button
                                    onClick={() => {
                                      openModal();
                                    }}
                                  >
                                    Resgatar
                                  </button>
                                </Row>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </ClientDiv>
            )}

          {showProfile &&
            JSON.stringify(clientData) !== '[]' &&
            clientData.clientExist === false && (
              <ClientDiv>
                <div
                  style={{
                    textAlign: 'left',
                    fontSize: '20px',
                    paddingLeft: 15,
                    color: '#7A7A7A',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '60%', color: '#EE5858' }}>
                      Não Cadastrado
                    </div>
                    <div
                      style={{
                        width: '40%',
                        textAlign: 'right',
                        marginRight: 28,
                      }}
                    >
                      {settings.model === 'cashback' ? (
                        <div style={{ color: '#2DB42D' }}>
                          R${clientData.totalClientCashback}
                        </div>
                      ) : (
                        <div style={{ color: '#2DB42D' }}>
                          {clientData.totalClientPoints} ponto(s)
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '60%', fontSize: 17 }}>
                      {clientData.numCompras + 1}ª compra
                    </div>
                  </div>
                </div>
              </ClientDiv>
            )}

          <div
            style={{
              borderBottom: '1px solid #D6D6D6',
              width: '98%',
              margin: 'auto',
              marginTop: 10,
            }}
          ></div>
          <HeaderPurchase>
            <div
              style={{
                margin: 'auto',
                color: '#2DB42D',
                fontSize: '20px',
                paddingLeft: 10,
              }}
            >
              Consumo:
            </div>
            <InputMask
              maskChar=""
              type="text"
              value={total}
              onChange={e => handleChangeTotal(e)}
              id="valor"
            />
          </HeaderPurchase>

          <div style={{ width: '100%', textAlign: 'center', color: '#999999' }}>
            {settings && settings.model === 'cashback' ? (
              <span>
                Essa compra renderá R${isNaN(cash) ? '0.00' : cash} de cashback
              </span>
            ) : (
              <span>
                Essa compra renderá {isNaN(cash) ? '0' : cash} ponto(s)
              </span>
            )}
          </div>

          <div
            style={{
              borderBottom: '1px solid #D6D6D6',
              width: '98%',
              margin: 'auto',
              marginTop: 10,
            }}
          ></div>

          <SelectField>
            <p>Segmentações</p>
            <SelectStyled
              name="products"
              options={products}
              placeholder="Selecione as segmentações..."
              isSearchable
              isMulti
              onChange={e => setSelected(e)}
              noOptionsMessage={() => 'nada encontrado ...'}
            />
          </SelectField>

          {user.plan === 'premium' && (
            <SelectField>
              <p>Lembrete</p>
              <SelectStyled
                name="lembrete"
                options={reminders}
                placeholder="Adicionar lembrete..."
                defaultValue={{
                  value: defaultReminder ? defaultReminder.value : null,
                  label: defaultReminder ? defaultReminder.label : 'Nenhum',
                }}
                isSearchable
                onChange={e => {
                  setSelectedLembrete(e.value);
                }}
                noOptionsMessage={() => 'nada encontrado ...'}
              />
            </SelectField>
          )}

          <div
            style={{
              paddingTop: 20,
              padding: 15,
            }}
          >
            <Row>
              Compra:
              <div
                style={{ float: 'right', fontFamily: 'Montserrat, sans-serif' }}
              >
                R${total === '' ? '0,00' : total}
              </div>
            </Row>
            {pointsModel ? (
              selectedPromotion.length > 0 ? (
                selectedPromotion.map(selected => (
                  <Row style={{ color: 'red', paddingTop: 10 }}>
                    Resgate:
                    <div style={{ float: 'right' }}>
                      {selected
                        ? `${selected.name} (${selected.points} p.)`
                        : '-'}
                    </div>
                  </Row>
                ))
              ) : (
                <Row style={{ color: 'red', paddingTop: 10 }}>
                  Resgate:
                  <div style={{ float: 'right' }}>-</div>
                </Row>
              )
            ) : (
              <Row style={{ color: 'red', paddingTop: 10 }}>
                Cashback Resgatado:
                <div
                  style={{
                    float: 'right',
                    fontFamily: 'Montserrat, sans-serif',
                  }}
                >
                  - R${rescued || '0.00'}
                </div>
              </Row>
            )}

            <Row style={{ color: 'green', paddingTop: 10 }}>
              Total:
              <div
                style={{ float: 'right', fontFamily: 'Montserrat, sans-serif' }}
              >
                R${total === '' ? '0,00' : total}
              </div>
            </Row>
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Descrição do Item"
          >
            <ModalTitle>
              <p>Aplicar Oferta</p>
              <p style={{ color: 'green' }}>
                Disponível:{' '}
                {parseInt(clientData.totalClientPoints) - pointsSelected}{' '}
                ponto(s)
              </p>
            </ModalTitle>
            <ModalBody>
              {promotions.map(
                promotion =>
                  parseInt(promotion.points) <=
                    parseInt(clientData.totalClientPoints) - pointsSelected && (
                    <ModalItem key={promotion.id}>
                      <div>
                        <p>{promotion.name}</p>
                        <p style={{ color: 'grey' }}>
                          {promotion.points} ponto(s)
                        </p>
                      </div>
                      <div style={{ marginLeft: 'auto' }}>
                        <ConfirmButton
                          style={{ height: '25px' }}
                          onClick={() => {
                            selectPromotion(promotion);
                            closeModal();
                          }}
                        >
                          Aplicar
                        </ConfirmButton>
                      </div>
                    </ModalItem>
                  ),
              )}
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={closeModal}>Cancelar</CancelButton>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={modalIsOpenConfirm}
            onRequestClose={closeModalConfirm}
            style={customStylesConfirm}
            contentLabel="Compra realizada!"
          >
            <ModalBody style={{ border: 'none' }}>
              <img
                ref={gif}
                src={confirmadoGif}
                width="150"
                height="150"
                style={{ margin: 'auto' }}
              />
              <span style={{ textAlign: 'center' }}>Compra Realizada!</span>
            </ModalBody>
          </Modal>

          <Footer>
            <ButtonAction>
              <ButtonOption to={'/history'}>
                <MdHistory size={22} color="#028D65" />
                Histórico
              </ButtonOption>
              <ButtonOption onClick={() => handlePurchase(phone, total)}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: 35,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ButtonGoToCart>
                    <MdAddShoppingCart size={30} color="white" />
                  </ButtonGoToCart>
                  <span>Confirmar</span>
                </div>
              </ButtonOption>
              <ButtonOption to={'/extract'} path={path}>
                <TiDocumentText size={22} color="#028D65" />
                Extrato
              </ButtonOption>
            </ButtonAction>
          </Footer>
        </Content>
      )}
    </Container>
  );
};

export default Operation;
